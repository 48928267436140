@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    @apply text-dark-600;
    -webkit-tap-highlight-color: transparent;
  }

  html.dark {
    @apply bg-dark-100 text-light-900;
  }

  html,
  body {
    @apply h-full antialiased font-body;
  }

  #__next {
    @apply h-full text-13px;
  }

  p {
    @apply mb-5;
  }

  p:last-of-type {
    @apply mb-0;
  }

  p > strong {
    @apply font-semibold;
  }

  p > a {
    @apply transition;
  }
  p > a:hover {
    color: #ff8a00;
    @apply transition;
  }

  .order-list-enable ol {
    @apply list-decimal space-y-1.5 ltr: pl-4 rtl:pr-4 ltr:lg:pl-8 rtl:lg:pr-8;
  }

  button {
    @apply focus:outline-none;
  }

  /* custom scrollbar */
  ::-webkit-scrollbar {
    @apply h-1.5 w-1.5;
  }

  ::-webkit-scrollbar-thumb {
    @apply bg-dark-900;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.4);
  }
}

/* custom utilities */
@layer utilities {
  .h-inherit {
    height: inherit;
  }

  .active-text-dark {
    text-shadow: 0 0 #181818;
  }

  .active-text-light {
    text-shadow: 0 0 #e6e6e6;
  }

  .transition-fill-colors {
    transition-property: background-color, fill;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }

  .sp-box-shadow {
    box-shadow:
      0 0 5px #03e9f4,
      0 0 25px #03e9f4,
      0 0 50px #03e9f4,
      0 0 100px;
  }

  .glow-icon {
    background: #03e9f4;

    /* box-shadow: 0 0 5px #03e9f4,
                0 0 25px #03e9f4,
                0 0 50px #03e9f4,
                0 0 200px #03e9f4; */
    /* -webkit-box-reflect:below 1px linear-gradient(transparent, #0005); */
  }
}

@layer components {
  /* .nav-btn {
    @apply hover:text-purple-500 active:translate-y-1 transition
  } */

  .nav-btn:hover::after {
    position: absolute;
    content: '';
    top: 44px;
    background-color: #fff;
    width: 50px;
    display: flex;
    height: 40px;
    margin-left: -11.5px;
    box-shadow:
      0 0 5px #03e9f4,
      0 0 25px #03e9f4,
      0 0 50px #03e9f4,
      0 0 200px #03e9f4;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
  }

  .nav-btn-w:hover::after {
    position: absolute;
    content: '';
    top: 44px;
    background-color: #fb923c;
    width: 50px;
    display: flex;
    height: 40px;
    margin-left: -11.5px;
    box-shadow:
      0 0 5px #fb923c,
      0 0 25px #fb923c,
      0 0 50px #fb923c,
      0 0 200px #fb923c;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
  }

  .m-circle,
  .m-circle:before {
    position: absolute;
    border-radius: 150px;
  }
  .m-circle {
    width: 100%;
    height: 100%;
    z-index: 0;
    display: flex;
    padding: 0px;
    border: 3px solid #fb923c;
    top: -5px;
  }
  .m-circle:hover {
    box-shadow:
      0 0 5px #fb923c,
      0 0 25px #fb923c,
      0 0 50px #fb923c,
      0 0 100px;
    /* box-shadow: 0 0 5px #fff, 0 0 25px #fff,0 0 50px #fff,0 0 100px; */
    /* box-shadow: 0 0 5px #03e9f4, 0 0 25px #03e9f4,0 0 50px #03e9f4,0 0 100px; */
  }

  .m-circle:before {
    content: '';
    display: flex;
    z-index: -1;
    width: 100%;
    height: 100%;
    background-color: #000;
  }
  .m-circle img {
    width: 100%;
    height: 100%;
    position: absolute;
    padding: 10%;
    top: -3px;
    left: 0px;
  }
  .glass-back {
    border-radius: 6px;
    border: 1px solid rgb(255 255 255 / 60%);
    background: rgba(255, 255, 255, 0.1);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(18px);
    -webkit-backdrop-filter: blur(18px);
    border: 1px solid rgba(255, 255, 255, 0.18);
    padding: 10px 0;
    /* z-index: 10; */
    /* color: whitesmoke; */
  }

  .header-logo {
    width: 180px;
    height: 66px;
  }

  .header-logo-mobile {
    width: 105px;
    height: 38.5px;
  }

  .overflow-text {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .tabs input[name='tab-control'] {
    display: none;
  }

  .tabs .tab-content section {
    display: none;
    line-height: 1.4;
  }

  .tabs input[id='tab1']:checked ~ .tab-content > #sec-1,
  .tabs input[id='tab2']:checked ~ .tab-content > #sec-2,
  .tabs input[id='tab3']:checked ~ .tab-content > #sec-3,
  .tabs input[id='tab4']:checked ~ .tab-content > #sec-4,
  .tabs input[id='tab5']:checked ~ .tab-content > #sec-5,
  .tabs input[id='tab6']:checked ~ .tab-content > #sec-6,
  .tabs input[id='tab7']:checked ~ .tab-content > #sec-7 {
    display: flex;
  }

  .tabs input[id='tab1']:checked ~ .tab-head ul > li:nth-child(1) > label,
  .tabs input[id='tab2']:checked ~ .tab-head ul > li:nth-child(2) > label,
  .tabs input[id='tab3']:checked ~ .tab-head ul > li:nth-child(3) > label,
  .tabs input[id='tab4']:checked ~ .tab-head ul > li:nth-child(4) > label,
  .tabs input[id='tab5']:checked ~ .tab-head ul > li:nth-child(5) > label,
  .tabs input[id='tab6']:checked ~ .tab-head ul > li:nth-child(6) > label,
  .tabs input[id='tab7']:checked ~ .tab-head ul > li:nth-child(7) > label {
    cursor: pointer;
    color: #000;
    background: #fff;
  }

  .tabs input[id='tab1']:checked ~ .slider,
  .tabs input[id='tab2']:checked ~ .slider,
  .tabs input[id='tab3']:checked ~ .slider,
  .tabs input[id='tab4']:checked ~ .slider,
  .tabs input[id='tab5']:checked ~ .slider,
  .tabs input[id='tab6']:checked ~ .slider,
  .tabs input[id='tab7']:checked ~ .slider {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }

  .ribbon {
    position: absolute;
    z-index: 2;
    margin-bottom: 30px;
    text-transform: uppercase;
    color: white;
  }
  .ribbon2 {
    width: 50px;
    padding: 10px 0;
    position: absolute;
    top: -6px;
    left: 5px;
    text-align: center;
    border-top-left-radius: 3px;
    background: #bd0606d4;
  }
  .ribbon2:before {
    height: 0;
    width: 0;
    right: -5.5px;
    top: 0.1px;
    border-bottom: 6px solid #ad3b3bad;
    border-right: 6px solid transparent;
  }
  .ribbon2:before,
  .ribbon2:after {
    content: '';
    position: absolute;
  }
  .ribbon2:after {
    height: 0;
    width: 0;
    bottom: -29.5px;
    left: 0;
    border-left: 25px solid #bd0606d4;
    border-right: 25px solid #bd0606d4;
    border-bottom: 30px solid transparent;
  }

  .ribbon3 {
    width: 150px;
    height: auto;
    padding-left: 10px;
    padding-top: 4px;
    position: absolute;
    left: -22px;
    top: 40px;
    background: #0fa945db;
  }
  .ribbon3:before,
  .ribbon3:after {
    content: '';
    position: absolute;
  }
  .ribbon3:before {
    height: 0;
    width: 0;
    top: -8.5px;
    left: 0.1px;
    border-bottom: 9px solid #045c23;
    border-left: 9px solid transparent;
  }

  .ribbon3._red:before {
    border-bottom: 9px solid #ed5b5b;
  }
  .ribbon3._red {
    background: #b91c1c;
  }

  .ribbon3._blue:before {
    border-bottom: 9px solid #0471b1;
  }
  .ribbon3._blue {
    background: #009df9;
  }

  .ribbon4 {
    position: absolute;
    top: 15px;
    padding: 8px 10px;
    background: #009df9;
    box-shadow: -1px 2px 3px rgba(0, 0, 0, 0.3);
  }
  .ribbon4:before,
  .ribbon4:after {
    content: '';
    position: absolute;
  }
  .ribbon4:before {
    width: 7px;
    height: 100%;
    top: 0;
    left: -6.5px;
    padding: 0 0 7px;
    background: inherit;
    border-radius: 5px 0 0 5px;
  }
  .ribbon4:after {
    width: 5px;
    height: 5px;
    bottom: -5px;
    left: -4.5px;
    background: lightblue;
    border-radius: 5px 0 0 5px;
  }

  .ribbon5 {
    display: block;
    width: calc(100% + 15px);
    height: 50px;
    line-height: 50px;
    text-align: center;
    margin-left: -19px;
    margin-right: -10px;
    background: #edba19;
    position: relative;
    top: 0px;
  }
  .ribbon5:before,
  .ribbon5:after {
    content: '';
    position: absolute;
  }
  .ribbon5:before {
    height: 0;
    width: 0;
    bottom: -10px;
    left: 0;
    border-top: 10px solid #cd8d11;
    border-left: 10px solid transparent;
  }
  .ribbon5:after {
    height: 0;
    width: 0;
    right: 0;
    bottom: -10px;
    border-top: 10px solid #cd8d11;
    border-right: 10px solid transparent;
  }

  /* Ribbon 6 */
  .wrap {
    width: 100%;
    height: 188px;
    position: absolute;
    top: -8px;
    left: 8px;
    overflow: hidden;
  }
  .wrap:before,
  .wrap:after {
    content: '';
    position: absolute;
  }
  .wrap:before {
    width: 40px;
    height: 8px;
    right: 100px;
    background: #4d6530;
    border-radius: 8px 8px 0px 0px;
  }
  .wrap:after {
    width: 8px;
    height: 40px;
    right: 0px;
    top: 100px;
    background: #4d6530;
    border-radius: 0px 8px 8px 0px;
  }
  .ribbon6 {
    width: 200px;
    height: 40px;
    line-height: 40px;
    position: absolute;
    top: 30px;
    right: -50px;
    z-index: 2;
    overflow: hidden;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    border: 1px dashed;
    box-shadow:
      0 0 0 3px #57dd43,
      0px 21px 5px -18px rgba(0, 0, 0, 0.6);
    background: #57dd43;
    text-align: center;
  }

  .ribbon7 {
    display: block;
    width: 160px;
    padding: 5px 20px;
    position: absolute;
    right: -14px;
    background: #0080c3;
    overflow: visible;
    height: auto;
    color: #fff;
    text-decoration: none;
    border-bottom-left-radius: 19px;
    border-top-right-radius: 3px;
    z-index: 2;
  }

  .ribbon7:after {
    /* this creates the "folded" part of our ribbon */
    content: '';
    height: 0;
    width: 0;
    display: block;
    position: absolute;
    bottom: -15px;
    right: 0;
    border-top: 15px solid #036da5;
    border-right: 15px solid transparent;
  }

  .ribbon7:before {
    content: '';
    height: 0;
    width: 0;
    display: block;
    position: absolute;
    top: 0;
    left: -20px;
    border-top: 28px solid #0080c3;
    border-left: 30px solid transparent;
  }

  .btn-enter-giveaway {
    color: #040404;
    padding: 10px 20px;
    border-radius: 30px;
    border: 1px solid #ffffff;
    width: 90%;
  }

  .payment-form [type='submit'] {
    padding: 10px 20px;
    background-color: #fcaa00;
    width: 100%;
    cursor: pointer;
    border-radius: 3px;
    color: black;
    text-transform: uppercase;
    font-weight: 600;
  }

  /* 

@-webkit-keyframes content {
  from {
    opacity: 0;
    -webkit-transform: translateY(5%);
            transform: translateY(5%);
 }
  to {
    opacity: 1;
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
 }
}
@keyframes content {
  from {
    opacity: 0;
    -webkit-transform: translateY(5%);
            transform: translateY(5%);
 }
  to {
    opacity: 1;
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
 }
}
@media (max-width: 1000px) {
  .tabs ul li label {
    white-space: initial;
 }
} */

  .animate-ping {
    animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
  }

  @keyframes ping {
    75%,
    100% {
      transform: scale(2);
      opacity: 0;
    }
  }
}

/**
 * Need these custom style
 * for header & category filter bar
 * special sticky control in mobile
 */
@media (max-width: 639px) {
  .app-category-filter-bar.sticky,
  .mobile-sticky-after-header {
    transition: transform 0.3s ease-in-out;
  }

  .scroll-down .app-category-filter-bar.sticky {
    transform: translate3d(0, -100%, 0);
  }

  /*  */
  .scroll-up .mobile-sticky-after-header {
    transform: translate3d(0, -100%, 0);
  }
}

/* cart tick animated icon styles */
.circle {
  opacity: 0;
  stroke-dasharray: 130;
  stroke-dashoffset: 130;
  transition: all 0.7s;
}

.tick {
  stroke-dasharray: 50;
  stroke-dashoffset: 50;
  transition: stroke-dashoffset 0.7s 0.3s ease-out;
}

.is-carting .path {
  opacity: 1;
  stroke-dashoffset: 0;
}

.react-multi-carousel-dot-list {
  bottom: 30px !important;
  gap: 5px !important;
}
.react-multi-carousel-dot--active button {
  background: #fff !important;
}
.react-multi-carousel-dot button {
  border: none !important;
  background: gray;
}

/* react phone input 2 */
html .react-tel-input .form-control {
  @apply h-11 w-full rounded border border-light-500 bg-transparent text-13px text-dark ring-[0.5px] ring-light-500 focus:border-brand focus:ring-[0.5px] focus:ring-brand md:h-12 xl:h-[50px];
}

html.dark .react-tel-input .form-control {
  @apply border-dark-600 text-light ring-dark-600 focus:border-brand focus:ring-brand;
}

html .react-tel-input .flag-dropdown {
  @apply top-[1px] left-[1px] rounded-tl-[3px] rounded-bl-[3px] border-0 bg-light-300;
  height: calc(100% - 2px);
}

html .react-tel-input .flag-dropdown.open,
html .react-tel-input .flag-dropdown.open .selected-flag {
  @apply rounded-tl-[3px] rounded-bl-[3px] bg-light-300;
}

html .react-tel-input .selected-flag:hover,
html .react-tel-input .selected-flag:focus {
  @apply bg-light-300;
}

html .react-tel-input .country-list {
  @apply text-13px;
}

html.dark .react-tel-input .flag-dropdown,
html.dark .react-tel-input .flag-dropdown.open,
html.dark .react-tel-input .flag-dropdown.open .selected-flag,
html.dark .react-tel-input .selected-flag:hover,
html.dark .react-tel-input .selected-flag:focus,
html.dark .react-tel-input .country-list {
  @apply bg-dark-300;
}

html.dark .react-tel-input .country-list .country.highlight,
html.dark .react-tel-input .country-list .country:hover {
  @apply bg-dark-200;
}

html {
  scroll-behavior: smooth;
}

/* React Share button custom styles */
.product-share .react-share__ShareButton > svg > circle {
  @apply transition-all stroke-1 fill-transparent stroke-light-800 dark:stroke-dark-600;
}

.product-share .react-share__ShareButton > svg > path {
  @apply transition-all scale-90 translate-x-1 translate-y-1 fill-dark-800 dark:fill-light;
}

.product-share .react-share__ShareButton:hover > svg > circle {
  @apply fill-light-200 dark:fill-dark-600;
}

.product-share .react-share__ShareButton:hover > svg > path {
  @apply fill-dark-300 dark:fill-light-300;
}

/* product gallery active status */
#productGalleryThumbs .swiper-wrapper .swiper-slide.swiper-slide-thumb-active,
#reviewGalleryThumbs .swiper-wrapper .swiper-slide.swiper-slide-thumb-active {
  @apply border-brand;
}

#productGallery + div > .swiper-button-disabled,
#reviewGallery + div > .swiper-button-disabled {
  @apply shadow-none;
}

#productGallery + div > .swiper-button-disabled:hover,
#reviewGallery + div > .swiper-button-disabled:hover {
  @apply bg-light text-dark/90;
}

/* StripeElement */
.StripeElement {
  @apply box-border h-[46px] rounded border border-light-500 bg-light py-4 pl-4 pr-2.5 text-13px text-dark ring-[0.5px] ring-light-500 hover:border-brand hover:ring-[0.5px] hover:ring-brand md:h-12;
}

.payment-modal .StripeElement.StripeElement--focus {
  @apply border-brand ring-brand;
}

/* Checkbox component */
.checkbox-component + span {
  @apply h-5 w-5 p-2.5 cursor-pointer rounded border border-light-500 ring-[0.5px] ring-light-500 dark:border-dark-600 dark:ring-dark-600;
}

.checkbox-component:checked + span {
  @apply border-brand bg-brand ring-brand;
}

.checkbox-component:checked + span::before {
  @apply absolute inset-auto -mt-[4px] -ml-[7px] flex h-2 w-3 border-2 border-t-0 border-r-0  border-dark-100 content-[''];
  transform: rotate(-45deg) translate(2px, -1px);
}

/* Radio input component */
.radio-input-component + span {
  @apply h-5 w-5 p-2.5 cursor-pointer rounded-full border border-light-500 ring-[0.5px] ring-light-500 dark:border-brand dark:ring-brand;
}

.radio-input-component:checked + span {
  @apply border-brand bg-brand ring-brand;
}

.radio-input-component:checked + span::before {
  @apply absolute inset-auto -mt-[4px] -ml-[7px] flex h-2 w-3 border-2 border-t-0 border-r-0 border-dark-100 content-[''];
  transform: rotate(-45deg) translate(2px, -1px);
}

/* RC Rate Star */
.rc-rate .rc-rate-star {
  @apply text-dark-700;
}

.rc-rate .rc-rate-star-half .rc-rate-star-first,
.rc-rate .rc-rate-star-full .rc-rate-star-second,
.rc-rate .rc-rate-star-half:hover .rc-rate-star-first,
.rc-rate .rc-rate-star-full:hover .rc-rate-star-second {
  @apply text-brand;
}

/* Shop Single follow button */
.shopBanner .followButton {
  @apply h-9 min-h-[34px] text-xs sm:h-9 sm:min-h-[34px] md:px-4;
}

/* rc table on report page */
/* RC Table */
.rc-table {
  @apply border border-separate border-gray-200 rounded-md text-13px dark:border-dark-500 dark:shadow-none;
  border-spacing: 0;
}

.rc-table .rc-table-placeholder {
  @apply text-sm;
}

.rc-table .rc-table-content,
.rc-table .rc-table-body,
.rc-table .rc-table-header {
  @apply border-0;
}

.rc-table .rc-table-content::-webkit-scrollbar {
  @apply h-[3px];
}

.rc-table .rc-table-content::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.3);
  border-radius: 0px;
}

.rc-table .rc-table-content::-webkit-scrollbar-thumb {
  @apply rounded-md bg-light-800 dark:bg-dark-700;
}

.rc-table tr th {
  @apply border-0 border-b border-gray-200 dark:border-dark-600;
}

.rc-table thead.rc-table-thead th {
  @apply border-b !border-gray-200 text-13px dark:!border-dark-600 dark:text-light/60;
}

.rc-table thead.rc-table-thead th:first-child {
  @apply pl-5 rounded-tl-md;
}

.rc-table thead.rc-table-thead th:last-child {
  @apply rounded-tr-md;
}

.rc-table tr.rc-table-row td:first-child {
  @apply pl-5;
}

.rc-table tr.rc-table-row td:last-child {
  @apply pr-5;
}

.rc-table tbody tr td {
  @apply border-0 border-b border-gray-200 !bg-transparent dark:border-dark-600;
}

.rc-table .rc-table-tbody tr.rc-table-row:last-child td:first-child {
  @apply rounded-bl-md;
}

.rc-table .rc-table-tbody tr.rc-table-row:last-child td:last-child {
  @apply rounded-br-md;
}

.rc-table .rc-table-tbody tr.rc-table-row:last-child td,
.rc-table .rc-table-placeholder td {
  @apply border-0;
}

.rc-table .rc-table-cell.rc-table-cell-row-hover {
  @apply !bg-brand/10;
}

.rc-table thead .rc-table-cell-scrollbar::after {
  background-color: transparent !important;
}

.rc-table thead.rc-table-thead th {
  @apply px-4 py-3 font-medium bg-gray-100 border-transparent font-body text-13px dark:bg-dark-400;
}

.rc-table tr.rc-table-row td {
  @apply px-4 py-3 font-normal font-body text-13px dark:text-white;
}

.rc-table tr.rc-table-row:not(.rc-table-expanded-row) {
  cursor: pointer;
}

.rc-table tr.rc-table-expanded-row > td {
  @apply p-0 whitespace-normal border border-gray-50;
}

.rc-table tr.rc-table-expanded-row tr.rc-table-row {
  cursor: default;
}

.rc-table .rc-table-expanded-row-fixed::after {
  border-width: 0;
}

.rc-table.card-view-table .rc-table-content {
  @apply pb-32;
}

.rc-table.card-view-table .rc-table-tbody tr.rc-table-row:last-child td {
  @apply border-b;
}

.rc-table.card-view-table
  .rc-table-tbody
  .rc-table-placeholder
  .rc-table-expanded-row-fixed {
  width: calc(100% - 5px) !important;
}

[dir='rtl'] .react-tel-input .selected-flag {
  padding: 0 8px 0 0;
}

[dir='rtl'] .react-tel-input .country-list .flag {
  margin-right: 0;
  margin-left: 7px;
}

[dir='rtl'] .react-tel-input .selected-flag .arrow {
  left: auto;
  right: 20px;
}

[dir='rtl'] .react-tel-input .country-list {
  left: 0;
}

.razorpay-checkout-frame {
  color-scheme: light;
}

.razorpay-backdrop {
  @apply hidden;
}

/* Show content based on checked radio button */
#tab-description:checked ~ #description {
  display: block;
}

/* Show content based on checked radio button */
#tab-history-giveaway:checked ~ #giveaway-history {
  display: block;
}

/* Hide giveaway-history content by default */
#giveaway-history {
  display: none;
}

.ag-center-cols-viewport {
  background: #faf9f6 !important;
}
